import React, { FC, useCallback, useState, useEffect } from 'react'

import { Avatar, Group, Icon, Item, Subheader, TextButton, Image } from '@revolut/ui-kit'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'

import {
  EditableListItem,
  EditableListItemValue,
  EditableOption,
  Icon as IconType,
} from 'types'
import { useMoveToTheNextView } from '../../../providers'
import { getFlagForCountry } from '../../helpers'

type Props = EditableListItem & {
  changeValue(value: EditableListItemValue): void
}

const getItemAvatarProps = (icon?: Partial<IconType>) => {
  if (icon?.countryCode) return { image: getFlagForCountry(icon.countryCode) }
  if (icon?.name) return { useIcon: icon.name as ResolveIconType }
  if (icon?.url) return { image: icon.url }
  return {}
}

const EditableList: FC<Props> = ({
  title,
  value,
  items,
  emptyListPlaceholder,
  addActionId,
  actions,
  changeValue,
  onActionClick,
}) => {
  const moveToTheNextView = useMoveToTheNextView()

  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)

  const addAction = addActionId && actions?.find(action => action.id === addActionId)

  useEffect(() => {
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  const handleItemClick = useCallback(
    (itemId: string) => {
      changeValue({
        items:
          value?.items.map(item => ({
            ...item,
            selected: item.id === itemId,
          })) ?? [],
      })
      setIsMoveToTheNextViewPending(true)
    },
    [value, changeValue],
  )

  const badge = (item: EditableOption) => {
    if (item.style === 'WARNING') {
      return <Avatar.Badge useIcon="16/ExclamationMarkSign" bg="warning" />
    }
    if (item.style === 'ERROR') {
      return <Avatar.Badge useIcon="16/ExclamationMarkSign" bg="danger" />
    }
    if (item.style === 'PENDING') {
      return <Avatar.Badge useIcon="16/SandWatch" bg="gray" />
    }

    return null
  }

  return (
    <>
      {(title || addAction) && (
        <Subheader>
          {title && <Subheader.Title>{title}</Subheader.Title>}
          {addAction && (
            <Subheader.Side>
              <TextButton onClick={() => onActionClick(addActionId)}>
                {addAction.title}
              </TextButton>
            </Subheader.Side>
          )}
        </Subheader>
      )}

      <Group>
        {items.map(item => (
          <Item key={item.id} use="button" onClick={() => handleItemClick(item.id)}>
            <Item.Avatar>
              <Avatar variant="brand" {...getItemAvatarProps(item.icon)}>
                {badge(item)}
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{item.title}</Item.Title>
              {item.valuePlaceholder && (
                <Item.Description
                  {...(item.style === 'WARNING' ? { color: 'warning' } : {})}
                >
                  {item.valuePlaceholder}
                </Item.Description>
              )}
            </Item.Content>
            <Item.Side>
              <Icon name="Pencil" color="accent" />
            </Item.Side>
          </Item>
        ))}
      </Group>

      {items.length === 0 && emptyListPlaceholder && (
        <Item>
          <Item.Avatar>
            <Image src="https://assets.revolut.com/assets/3d-images-v2/3D097@2x.png" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title color="grey-tone-50">{emptyListPlaceholder}</Item.Title>
          </Item.Content>
        </Item>
      )}
    </>
  )
}

export default EditableList
